document.addEventListener('DOMContentLoaded', function() {
    var loginButton = document.createElement('button');
    loginButton.classList.add('bsk-btn');
    loginButton.classList.add('bsk-btn-default');
    loginButton.innerHTML = '<object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon"></object> <span class="en">Sign in with Microsoft</span><span class="fr">Se connecter avec Microsoft</span>';
    var loginContainer = document.createElement('div');
    loginContainer.classList.add('bsk-container');
    loginContainer.appendChild(loginButton);
    var loginSection = document.createElement('section');
    loginSection.classList.add('bsk-section');
    loginSection.appendChild(loginContainer);
    var loginForm = document.getElementById('loginform');
    //if (window.location.href.indexOf('?alt=1') == -1) {
        loginForm.parentNode.insertBefore(loginSection, loginForm);

        if (window.location.href.indexOf('stereodev.ca') != -1 && window.location.href.indexOf('.test') != -1) {
            loginForm.parentNode.removeChild(loginForm);
        }
    //} else {
        //loginForm.style.display = 'block';

    //}

    //event onclick button to redirect to microsoft login
    loginButton.addEventListener('click', function() {
        window.location.href = '/oauth/login';
    });


});